<template>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <div class="card-title">
      </div>
      <div class="card-toolbar">
        <div class="me-2">
          <el-button size="large" v-if="total > 0" class="btn btn-sm btn-dark" type="primary" data-bs-toggle="modal" data-bs-target="#order_detail_fields_modal" :loading="loading"><i class="bi bi-file-earmark-spreadsheet fs-4"></i> {{ "Export Data" }}</el-button>
        </div>
        <button type="button" class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end" >
          <span class="svg-icon svg-icon-2">
            <inline-svg src="/media/icons/duotune/general/gen024.svg" />
          </span>
        </button>
        <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true">
          <div class="px-7 py-5">
            <div class="fs-5 text-dark fw-bolder">{{generalConstants.FILTER_COLUMNS}}</div>
          </div>
          <div class="separator border-gray-200"></div>
          <div class="px-7 py-5">
            <div class="mb-10">
              <el-checkbox
                :disabled="visibleFields.length == 1 && header.visible"
                v-for="header in tableHeader" 
                :key="header.key" 
                v-model="header.visible" 
                inline >
                {{ header.name }}
              </el-checkbox>
            </div>
            <div class="d-flex justify-content-end">
              <button type="submit" @click="submitFilters(visibleFields)" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true" > {{generalConstants.SAVE}} </button>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" tabindex="-1" id="order_detail_fields_modal">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Select Fields Which You Want To Export</h5>
            <div class="btn btn-icon btn-sm btn-active-color-primary ms-2" id="orderdetailclosebutton" data-bs-dismiss="modal" aria-label="Close">
              <span class="svg-icon svg-icon-1">
                <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
              </span>
            </div>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <el-checkbox-group v-model="checkedOrderDetailExportFields">
                  <el-checkbox v-for="(fields, index) in orderDetailExportFields" :key="index" :label="index">
                    {{ fields }}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-gray" @click="exportOrderDetailData('xlsx')"><i class="bi bi-file-earmark-arrow-down fs-4"></i>
              {{ "Export With Excel" }}
            </button>
            <button type="button" class="btn btn-gray" @click="exportOrderDetailData('csv')"><i class="bi bi-file-earmark-arrow-down fs-4"></i>
              {{ "Export With CSV" }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body pt-6">
      <div class="row">
        <div class="col-xl-12">
          <!--begin::Accordion-->
          <div class="accordion" id="kt_accordion_1">
            <div class="accordion-item">
              <h2 class="accordion-header" id="kt_accordion_1_header_1">
                <button class="accordion-button fs-4 fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#kt_accordion_1_body_1" aria-expanded="true" aria-controls="kt_accordion_1_body_1">
                  <h4 class="fw-bolder m-0">Filters</h4>
                </button>
              </h2>
              <div id="kt_accordion_1_body_1" class="accordion-collapse collapse" aria-labelledby="kt_accordion_1_header_1" data-bs-parent="#kt_accordion_1">
                <div class="accordion-body">
                  <div class="d-flex align-items-center position-relative my-1">
                    <div class="row">
                      <div class="col-md-2">
                        <input type="text" v-model="searchParams.search" v-on:keyup.enter="handleSearch()" class="form-control me-2" :placeholder="ordersConstants.SEARCH_ORDERS" />
                      </div>
                      <div class="col-md-2">
                        <el-select v-model="searchParams.partner" :placeholder="generalConstants.SELECTTRADINGPARTNER" size="large">
                          <el-option value="">{{generalConstants.SELECTTRADINGPARTNER}}</el-option>
                          <el-option v-for="partner in tradingPartners" :value="partner.trading_partner_id" :key="partner" :label="partner.user.name">
                            {{partner.user.name}}
                          </el-option>
                        </el-select>
                      </div>
                      <div class="col-md-3">
                        <el-date-picker
                          class="w-100 ml-4"
                          v-model="searchParams.searchByOrderDate"
                          type="daterange"
                          range-separator="To"
                          value-format="YYYY-MM-DD"
                          start-placeholder="Order date"
                          end-placeholder="Order date"
                          size="large"
                        />
                      </div>
                      <div class="col-md-3">
                        <el-date-picker
                          class="w-100 ml-4"
                          v-model="searchParams.searchByOrderDueDate"
                          type="daterange"
                          range-separator="To"
                          value-format="YYYY-MM-DD"
                          start-placeholder="Order Due date"
                          end-placeholder="Order Due date"
                          size="large"
                          :shortcuts="shortcuts"
                          unlink-panels
                        />
                      </div>
                      <div class="col-md-2">
                        <el-select v-model="searchParams.searchByOrderStatus" :placeholder="ordersConstants.ORDER_STATUS" size="large">
                          <el-option value="">{{ordersConstants.ORDER_STATUS}}</el-option>
                          <el-option v-for="status in orderStatus" :value="status.type" :key="status" :label="status.title">
                            {{status.title}}
                          </el-option>
                        </el-select>
                      </div>
                      <div class="col-md-2 mt-2">
                        <el-tooltip class="box-item" effect="dark" :content="generalConstants.SEARCH" placement="top">
                          <button type="button" @click.prevent="handleSearch()" class="btn btn-icon btn-light btn-sm me-2"><i class="bi bi-search"></i></button>
                        </el-tooltip>
                        <el-tooltip class="box-item" effect="dark" :content="generalConstants.RESET" placement="top">
                          <button type="button" @click.prevent="resetSearch" class="btn btn-icon btn-light btn-sm"><i class="bi bi-arrow-counterclockwise"></i></button>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end::Accordion-->
        </div>
      </div>
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :rows-per-page="searchParams.recordsPerPage"
        :empty-table-text="noDataFound"
        :loading="loading"
        :current-page="1"
        :enable-items-per-page-dropdown="true"
        :total="total"
        ref="updateProps"
        @current-change="handleCurrentChange"
        @items-per-page-change="handleSizeChange"
        @sort="handleSortChange"
      >
        <template v-slot:cell-order_date="{ row: data }">
          {{ data.order_date != NULL ? formatDate(data.order_date) : '-' }}
        </template>
        <template v-slot:cell-id="{ row: data }">
          <a href="javascript:void(0)" class="fw-bolder text-gray-600 text-hover-primary" @click.prevent="$router.push('/orders/'+data.id)"> #{{ data.id}}</a>
        </template>
        <template v-slot:cell-partner_detail="{ row: data }">
          <span v-if="data.order_id">{{ '('+data.order_id+')' }}<br></span>
          <span v-if="data.platform_name">Name: {{ data.platform_name }}<br></span>
        </template>
        <template v-slot:cell-order_detail="{ row: data }">
          <span v-if="data.order_product_status_title">Status: {{ data.order_product_status_title }}</span><br>
          <span v-if="data.payment_status">Payment Status: {{ data.payment_status }}</span><br>
          <span v-if="data.production_due_date">Due Date: {{ data.production_due_date }}</span>
        </template>
        <template v-slot:cell-customer_detail="{ row: data }">
          <span v-if="data.customer_name">Name: {{ data.customer_name }}<br></span>
          <span v-if="data.customer_phone">Phone: {{ data.customer_phone }}<br></span>
          <span v-if="data.customer_email">Email: {{ data.customer_email }}</span>
        </template>
        <template v-slot:cell-customer_address="{ row: data }">
          {{data.address1 + (data.address2 != null ? data.address2 : '') + (data.city != null ? ' '+data.city+',' : '') + (data.state != null ? ' '+data.state+',' : '') + (data.country != null ? ' '+data.country : '')}}
        </template>
        <template v-slot:cell-product_detail="{ row: data }">
          <span v-if="data.product_name">Name: {{ data.product_name }}</span><br>
          <span v-if="data.product_quantity">Quantity: {{ data.product_quantity }}</span><br>
          <span v-if="data.product_sku">SKU: {{ data.product_sku }}</span>
        </template>
        <template v-slot:cell-shipping_detail="{ row: data }">
          <span v-if="data.shipping_method">Method: {{ data.shipping_method }}</span><br>
          <span v-if="data.shipping_type">Type: {{ data.shipping_type }}</span><br>
          <span v-if="data.shipping_charges">Charges: {{ data.shipping_charges }}</span>
        </template>
        <template v-slot:cell-payment_detail="{ row: data }">
          <span v-if="data.payment_method">Method: {{ data.payment_method }}</span><br>
          <span v-if="data.payment_date">Date: {{ formatDate(data.payment_date) }}</span><br>
          <span v-if="data.product_discount">Discount: {{ data.product_discount }}</span>
          <span v-if="data.product_tax">Tax: {{ data.product_tax }}</span>
        </template>
        <template v-slot:cell-sub_total="{ row: data }">
          {{ data.product_subtotal != '' ? formatPrice(data.product_subtotal) : '-' }}
        </template>
        <template v-slot:cell-total_amount="{ row: data }">
          {{ formatPrice(data.total_amount) }}
        </template>
      </Datatable>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted, computed } from 'vue';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { formatText,formatDate, formatPrice } from "@/core/helpers/common";
import { useRoute, useRouter } from "vue-router";
import axios from "axios";
import { notificationFire } from "@/composable/notification.js";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import globalConstant from "@/core/data/globalConstant.js";
import { loggedInUserRoles, loggedInUserPermissions } from "@/composable/get-roles.js";

export default {
  name: "orders-detail",
  components: {
    Datatable
  },
  setup() {
    let total = ref(0);
    const tableData = ref([]);
    const loading = ref(false);
    const router = useRouter();
    const route = useRoute();
    const tradingPartners = ref([]);
    const generalConstants = globalConstant.general;
    const ordersConstants = globalConstant.orders;
    const noDataFound = ref(generalConstants.LOADING);
    const updateProps = ref(null);
    const checkedOrderDetailExportFields = ref([]);
    const orderStatus = ref([]);
    const searchParams = reactive({
      recordsPerPage: Number(JSON.parse(window.localStorage.getItem('site_config'))['per_page']),
      pageNub: 1,
      page_type: "order-details-report",
      partner:"",
      search:"",
      sortBy:"",
      sortDesc:"",
      searchByOrderDate:"",
      searchByOrderDueDate:"",
      isPendingOrder:0,
      user_id: 0,
      type:"listing",
      searchByOrderStatus: "",
    })

    const orderDetailExportFields = reactive({
      id: 'Order ID',
      order_date: 'Order Date',
      partner_detail: 'Partner Details',
      order_detail: 'Order Details',
      customer_detail: 'Customer Details',
      customer_address: 'Customer Address',
      product_detail: 'Product Details',
      shipping_detail: 'Shipping Details',
      payment_detail: 'Payment Details',
      sub_total: 'Sub Total',
      total_amount: 'Total'
    });

    const submitFilters = async (filters) => {
      
      if (!tableHeader) return
        let filterData = {
          orderDetailSummeryReportFilters: tableHeader.map(({key, visible}) => ({[key]: visible})),
        };
        ApiService.post('users/save-filters', filterData)
        .then((response) => {
          if (response.data) {
            let status = response.status;
            let message = response.data.message;
            if (status == 200 || status == 201) {
              notificationFire(message,'success');
            } else {
              notificationFire(message,'error');
            }
          }
        })
        .catch((error) => {
          loading.value = false;
          loadingback.value = false;
          let message = error.message;
          notificationFire(message,'error');
        });
    }

    //Export all ordre summery data in xlsx/csv file
    const exportOrderDetailData = async (type) => {
      searchParams.type = "export";
      axios({
        url: 'order-detail/export-data',
        method: 'POST',
        data: {
          params: searchParams,
          extension: type,
          exportFields: checkedOrderDetailExportFields.value
        },
        responseType: 'arraybuffer',
      }).then((response) => {
        document.getElementById('orderdetailclosebutton').click();
        let blob = new Blob([response.data], {
            type: ''
        })
        let link = document.createElement('a')
        link.setAttribute('target','_blank')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'Order_Details_Report_'+Date.now()+'.'+ type
        link.click()
      })
      .catch((error) => {
        let response = error;
          if (response != "") {
            notificationFire('order details data is not exported', "error");
          }
      });
    }

    const shortcuts = [
      {
        text: 'Last week',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        },
      },
      {
        text: 'Last month',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          return [start, end]
        },
      },
      {
        text: 'Last 3 months',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          return [start, end]
        },
      },
    ]
    const permission = reactive({
      isViewOrderDetailReportAllowed: false,
    })

    const tableHeader = reactive([
      {
        name: 'Order ID',
        key: "id",
        sortable: true,
        width: "w-100px",
        visible: true,
        alignment: 'text-start'
      },
      {
        name: 'Order Date',
        key: 'order_date',
        width: "w-150px",
        visible: true,
        alignment: 'text-start',
        sortable: false,
      },
      {
        name: 'Partner Details',
        key: 'partner_detail',
        width: "w-300px",
        visible: true,
        alignment: 'text-center'
      },
      {
        name: 'Order Details',
        key: 'order_detail',
        width: "w-300px",
        visible: true,
        alignment: 'text-center'
      },
      {
        name: 'Customer Details',
        key: 'customer_detail',
        width: "w-350px",
        visible: true,
        alignment: 'text-center'
      },
      {
        name: 'Customer Address',
        key: 'customer_address',
        width: "w-350px",
        visible: true,
        alignment: 'text-center'
      },
      {
        name: 'Product Details',
        key: 'product_detail',
        width: "w-350px",
        visible: true,
        alignment: 'text-center'
      },
      {
        name: 'Shipping Details',
        key: 'shipping_detail',
        width: "w-300px",
        visible: true,
        alignment: 'text-start',
        sortable: false,
      },
      {
        name: 'Payment Details',
        key: 'payment_detail',
        width: "w-350px",
        visible: true,
        alignment: 'text-start',
        sortable: false,
      },
      {
        name: 'Sub Total',
        key: 'sub_total',
        width: "w-100px",
        visible: true,
        alignment: 'text-center',
        sortable: false,
      },
      {
        name: 'Total',
        key: 'total_amount',
        width: "w-100px",
        visible: true,
        alignment: 'text-center',
        sortable: false,
      },
    ]);
    
    const visibleFields = computed(() => {
      return tableHeader.filter(header => header.visible)
    })

    const handleSizeChange = (number) => {
      searchParams.recordsPerPage = number;
      handleCurrentChange(1);
    }

    const handleSortChange = (obj) => {
      searchParams.sortBy = obj.columnName;
      searchParams.sortDesc = obj.order;
      getOrderDetailReportData();
    }
    
    const handleCurrentChange = (number) => {
      searchParams.pageNub = number;
      getOrderDetailReportData();
    }

    //Get all active statuses
    const getActiveStatusForOrders = async () => {
      loading.value = true;
      await ApiService.query("get-order-status")
      .then(({ data }) => {
        if (data.data) {
          orderStatus.value = data.data;
        } else {
          orderStatus.value = [];
        }
        loading.value = false;
      })
      .catch((error) => {
        //Toast the error here
        orderStatus.value = [];
        loading.value = false;
      });
    }

    //Get all orders detail report data
    const getOrderDetailReportData = async () => {
      loading.value = true;
      searchParams.type = "listing";
      await ApiService.post("reports/order-detail-summary", { params: searchParams })
      .then(({ data }) => {
        if(data.data.filter)
        {
          var filterData = data.data.filter;
          tableHeader.forEach((options, headerKey) => {
            let key = options.key;
            if (filterData[key] !== undefined) {
              tableHeader[headerKey].visible = filterData[key];
              if(filterData[key] == true){
                checkedOrderDetailExportFields.value.push(tableHeader[headerKey].key);
              }
            }
          });
        }
        if (data.data.data.length) {
          tableData.value.splice(0, tableData.value.length, ... data.data.data);
          total.value = data.data.total;
          updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub);
        }
        else
        {
            tableData.value = [];
            total.value = 0;
            updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub, generalConstants.NO_DATA_FOUND);
        }
        setCurrentPageBreadcrumbs(formatText(route.name) + " (" + total.value + ")", [{ name: formatText(route.name), active: true }]);
        loading.value = false;
      })
      .catch((error) => {
        //Toast the error here
        tableData.value = [];
        total.value = 0;
        loading.value = false;
      });
    }

    //Get all active trading partners
    const getTradingPartners = async () => {
      loading.value = true;
      await ApiService.query("get-active-trading-partner")
      .then(({ data }) => {
        if (data.data) {
          tradingPartners.value = data.data;
        } else {
          tradingPartners.value = [];
        }
        loading.value = false;
      })
      .catch((error) => {
        //Toast the error here
        tradingPartners.value = [];
        loading.value = false;
      });
    }

    //Reset search value
    const resetSearch = () => {
      if (searchParams.search.length > 0) {
        searchParams.search = "";
      }
      searchParams.partner = "";
      searchParams.searchByOrderDate = "";
      searchParams.searchByOrderDueDate = "";
      searchParams.searchByOrderStatus = "";
      getOrderDetailReportData();
    };

    //Order search event function
    const handleSearch = () => {
      handleCurrentChange(1);
    }

    //Export order summary data
    const exportOrderSummaryData = () => {
      searchParams.type = "download";
      axios({
        url: 'reports/order-detail-summary',
        method: 'POST',
        data: {
          params: searchParams,
        },
        responseType: 'arraybuffer',
      }).then((response) => {
        let blob = new Blob([response.data], {
            type: ''
        })
        let link = document.createElement('a')
        link.setAttribute('target','_blank')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'order_detail_'+Date.now()+'.xlsx'
        link.click()
      })
      .catch((error) => {
        let message = error.message;
        if (message != "") {
          notificationFire(message, "error");
        }
      });
      return false;
    }

    //Set breadcrumbs and get the orders detail data for report
    onMounted(async () => {
      let role = loggedInUserRoles();
      let per = loggedInUserPermissions();
      if (per.includes("ViewOrderDetailReport") || role == "Super Admin")
        permission.isViewOrderDetailReportAllowed = true;
      getTradingPartners();
      getActiveStatusForOrders();
      await getOrderDetailReportData();
      setCurrentPageBreadcrumbs(formatText(route.name) + " (" + total.value + ")", [{ name: formatText(route.name), active: true }]);
    })
    
    return {
      tableData,
      total,
      handleSizeChange,
      handleCurrentChange,
      handleSortChange,
      loading,
      searchParams,
      tradingPartners,
      getTradingPartners,
      tableHeader,
      generalConstants,
      noDataFound,
      updateProps,
      resetSearch,
      ordersConstants,
      handleSearch,
      shortcuts,
      permission,
      exportOrderSummaryData,
      formatDate,
      formatPrice,
      visibleFields,
      orderDetailExportFields,
      submitFilters,
      checkedOrderDetailExportFields,
      exportOrderDetailData,
      orderStatus
    }
  },
};

</script>
<style lang="scss">
.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(2.1em + 1.1rem + 2px);
  width: calc(2.1em + 1.1rem + 2px);
}
.el-link {
  justify-content: left;
}
.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(2.1em + 1.1rem + 2px);
  width: calc(2.1em + 1.1rem + 2px);
}
.btn.btn-purple{
  background-color: #8618cbbf;
  border-color: #8618cbbf;
  color:#ffffff;
}
.btn.btn-purple i{color:#ffffff;}
.btn.btn-purple svg path{fill:#ffffff !important;}
.btn.btn-purple:hover,.btn.btn-purple:focus,.btn.btn-purple:active{
  background-color: #6911a1bf;
  border-color: #6911a1bf;
}
.menu-state-bg-light-purple .menu-item.hover:not(.here) > .menu-link:not(.disabled):not(.active):not(.here), .menu-state-bg-light-purple .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here){
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #f5e7ff96;
  color: #8618cbbf;
}

// For Export Button
.btn.btn-gray{
  background-color: #858585;
  border-color: #858585;
  color:#ffffff;
}
.btn.btn-gray i{color:#ffffff;}
.btn.btn-gray svg path{fill:#ffffff !important;}
.btn.btn-gray:hover,.btn.btn-gray:focus,.btn.btn-gray:active{
  background-color: #666666;
  border-color: #666666;
}
.menu-state-bg-light-gray .menu-item.hover:not(.here) > .menu-link:not(.disabled):not(.active):not(.here), .menu-state-bg-light-gray .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here){
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #f3f3f3;
  color: #858585;
}
</style>